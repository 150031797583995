























import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokListItem } from '../../types';

@Component
export default class ItemList extends Vue {
  @Prop() index!: number;
  @Prop() blok!: BlokListItem;
  // -> GSAP animation
  $refs!: { itemlist: Element };
  hover (isForwards: boolean): void {
    if (!process.browser) { return; }
    if (isForwards) {
      this.$gsap.to(this.$refs.itemlist, {
        ease: 'power1.out',
        y: -8,
        duration: 0.3
      });
    } else {
      this.$gsap.to(this.$refs.itemlist, {
        ease: 'power1.in',
        y: 0,
        duration: 0.3
      });
    }
  }
}
